.ActiveTaskCard {
  height: 40px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.ActiveTaskCardInner {
  display: flex;
  align-items: center;
  height: 100%;
  width: 95%;
  margin-left: 3vw;
  margin-right: 3vw;
  justify-content: space-between;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  height: 100%;
}

.complete-button-task {
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #20e91d;
  padding: 6px;
  margin-right: 1vw;
  background: #20e91d;
  font-weight: bolder;
  color: white;
}

.complete-button-task:hover {
  transition: all 0.1s ease;
  scale: 1.1;
}

.recycle-button-task {
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #e9911d;

  background: #e9911d;
  padding: 6px;
  font-weight: bolder;
  color: white;
}

.recycle-button-task:hover {
  transition: all 0.1s ease;
  scale: 1.1;
}
