.reward-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 25%;
  border-radius: 10px;
  margin: 5px;
}

[gold-theme="gold"] {
  background-color: gold;
}

[silver-theme="silver"] {
  background-color: silver;
}

[bronze-theme="bronze"] {
  background-color: #cd7f32;
}

/* .map-container-reward .reward-card:nth-child(1) {
  background-color: gold;
  border: gold solid 5px;
}

.map-container-reward .reward-card:nth-child(2) {
  background-color: silver;
  border: silver solid 5px;
}

.map-container-reward .reward-card:nth-child(3) {
  background-color: #cd7f32;
  border: #cd7f32 solid 5px;
} */

.reward-content {
  margin: 1%;
}

.medal {
  width: 20%;
}

.reward-buttons {
  margin: 5px;
}

.delete-btn-reward {
  outline: 0;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid #ff4742;
  color: white;
  background: #ff4742;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
  height: 80%;
  width: 18%;
  margin-right: 1vw;
  font-weight: bolder;
}

.delete-btn-reward:hover {
  background-color: white;
  color: black;
}

.edit-btn-reward {
  outline: 0;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid rgb(149, 230, 125);
  color: white;
  background: rgb(149, 230, 125);
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
  height: 80%;
  width: 18%;
  margin-right: 1vw;
  font-weight: bolder;
}

.edit-btn-reward:hover {
  background-color: white;
  color: black;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
