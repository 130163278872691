.footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(white,var(--user-blue));
  text-align: center;
}