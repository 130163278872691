.rewardWindow {
  margin-top: 8vh;
  justify-self: center;
}

.save-edit-btn-reward {
  outline: 0;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid rgb(255, 208, 66);
  color: white;
  background: rgb(255, 208, 66);
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
  height: 50%;
  width: 50%;

  font-weight: bolder;
}

.save-edit-btn-reward:hover {
  background-color: white;
  color: black;
  scale: 1.1;
}

.edit-reward,
.editreward-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-reward {
  box-shadow: inset #abacaf 0 0 0 2px;
  border: 0;
  background: rgba(0, 0, 0, 0);
  appearance: none;
  width: 100%;
  position: relative;
  border-radius: 3px;
  padding: 9px 12px;
  line-height: 1.4;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  transition: all 0.2s ease;
  background-color: white;
  margin: 10px;
}

.addreward-btn:hover {
  transform: scale(1.04);
  background-color: rgb(107, 107, 228);
}

.add-reward-input-btn {
  cursor: pointer;
  display: inline-block;
  outline: 0;
  border: none;
  box-shadow: none;
  cursor: pointer;
  padding: 9px 22px;
  font-size: 22px;
  height: 50px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: normal;
  background: linear-gradient(90deg, #5522fa 0, #0074e4 100%);
  border-radius: 50px;
  transition: color 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;
}

.add-reward-input-btn:hover {
  box-shadow: 0 0 0 0.15rem #5ceace;
  scale: 1.1;
}

.add-reward {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 3vw;
  grid-row-gap: 1vh;
}

.add-reward-center {
  text-align: center;
}

select {
  font: inherit;
}

option {
  height: 30px;
}
