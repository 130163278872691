.TaskContainer {
  display: center;
  margin-top: 8vh;
  margin-left: 30vw;
  width: 40vw;
  border: 2px solid var(--user-blue);
  border-radius: 5px;
  align-content: center;
  align-self: center;
  font-family: var(--font-body);
}



.addtask-btn:hover {
  transform: scale(1.04);
  background-color: rgb(107, 107, 228);
}

.add-task-input-btn {
  cursor: pointer;
  display: inline-block;
  outline: 0;
  border: none;
  box-shadow: none;
  cursor: pointer;
  padding: 9px 22px;
  font-size: 22px;
  height: 50px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: normal;
  background: linear-gradient(90deg, #5522fa 0, #0074e4 100%);
  border-radius: 50px;
  transition: color .2s ease, background-color .2s ease, box-shadow .2s ease;
}

.add-task-input-btn:hover {
  box-shadow: 0 0 0 0.15rem #5ceace;
  scale: 1.1;
}

.add-task {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 3vw;
  grid-row-gap: 1vh;
}

.add-task-center {
  text-align: center;
}

select {
  font: inherit;
}

option {
  height: 30px;
}

.addtask-btn {
  cursor: pointer;
  outline: none;
  font-size: 14px;
  line-height: 1;
  border-radius: 500px;
  transition-property: background-color, border-color, color, box-shadow, filter;
  transition-duration: 0.3s;
  border: 1px solid transparent;
  letter-spacing: 2px;
  min-width: 160px;
  text-transform: uppercase;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 17px 48px 17px;
  color: #fff;
  background-color: var(--user-blue);
  margin-bottom: 1vh;
}