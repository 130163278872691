@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

:root {
  --user-lilac: #f1bdff;
  --user-green: #bfffd0;
  --user-blue: #9cbcff;
  --user-red: #ffa2a2;
  --user-yellow: #ffde9f;

  --font-body: Patrick Hand;
  --font-brand: pacifico;

  --background-color: white;
  --font-color: black
}

body {
  margin: 0;
  font-family: var(--font-body);
  background-color: white;
  color: var(--font-color);
  min-height: 100vh;
  transition: background-color 250ms ease-in-out
}

[data-theme="dark"] {
  --background-color: rgb(21, 21, 112);
  --font-color: white;

  .logoLarge path {
    fill: white;
    transition: fill 250ms ease-in-out;
  }
}

.input-field {
  font-family: inherit;
  text-align: center;
  font-size: large;
}