.login-button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    border-radius: 3px;
    padding: 12px 24px;
    border: 0;
    color: #000021;
    background: #1de9b6;
    line-height: 1.15;
    font-size: 16px;
    margin-right: 1%;


}

.top-page {
    margin-top: 8vh;
}

.netflix-container {
    display: grid;
    margin-top: 8vh;
    grid-row: 1;
    text-align: center;
    margin-bottom: 8vh;
    grid-template-columns: auto auto;
}

.indi-user-container {
    width: 45vw;
}


.login-button:hover {
    transition: all .1s ease;
    box-shadow: 0 0 0 0 #fff, 0 0 0 3px #1de9b6;
}


.name-map-items {
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2%;
}

.name-map-names {
    margin: 0 2% 0 2%
}

.delete-button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    border-radius: 3px;
    padding: 12px 24px;
    border: 0;
    color: #000021;
    background: rgb(184, 22, 22);
    line-height: 1.15;
    font-size: 16px;
}

.delete-button:hover {
    transition: all .1s ease;
    box-shadow: 0 0 0 0 #fff, 0 0 0 3px rgb(184, 22, 22);
}

.map-item-img {
    height: 5em;
}

.indi-user-container {
    margin-bottom: 3vh;
}

.create-new-user {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    border-radius: 500px;
    transition-property: background-color, border-color, color, box-shadow, filter;
    transition-duration: .3s;
    border: 1px solid transparent;
    letter-spacing: 2px;
    min-width: 160px;
    text-transform: uppercase;
    white-space: normal;
    font-weight: 700;
    text-align: center;
    padding: 17px 48px 17px;
    color: #fff;
    background-color: var(--user-blue);
    margin-bottom: 1vh;
}

.create-new-user:hover {
    transform: scale(1.04);
    background-color: rgb(107, 107, 228);

}

.add-member-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 1vw;
    grid-row-gap: 1vh;
}

.admin-checkbox-container {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.admin-checkbox-container-hide {
    font-size: 20px;
    opacity: 0;
}

#adminCheckBox {
    width: 20px;
    height: 20px;
}

select {
    font: inherit;
}

option {
    height: 30px;
}

#blue-option {
    background-color: var(--user-blue)
}

#lilac-option {
    background-color: var(--user-lilac);
}

#green-option {
    background-color: var(--user-green);
}

#yellow-option {
    background-color: var(--user-yellow);
}

#red-option {
    background-color: var(--user-red);
}

.input-field {
    box-shadow: inset #abacaf 0 0 0 2px;
    border: 0;
    background: rgba(0, 0, 0, 0);
    width: 100%;
    border-radius: 3px;
    line-height: 1.4;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 400;
    transition: all .2s ease;
    background-color: white;
}

.input-field:focus {
    background: #fff;
    outline: 0;
    box-shadow: 0 0 0 0 #fff inset, var(--user-blue) 0 0 0 3px;
}