.App {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
  background-color: var(--background-color);
  color: var(--font-color);
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out;
}

nav {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 15vh;
  justify-content: space-around;
  padding-bottom: 3vh;
}

.tasklink {
  display: grid;
  grid-template-rows: 5vh 10vh;
  justify-content: center;
  align-items: center;
  height: 15vh;
  width: 20vw;
  background-color: var(--user-blue);
  border-radius: 10px;
  color: white;
  text-decoration: none;
}

.leaderboardlink,
.rewardslink {
  height: 15vh;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 5vh 10vh;
  width: 20vw;
  background-color: var(--user-blue);
  border-radius: 10px;
  color: white;
  text-decoration: none;
}

.calendarlink {
  height: 15vh;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 5vh 10vh;
  width: 20vw;
  background-color: var(--user-blue);
  border-radius: 10px;
  color: white;
  text-decoration: none;
}
.navimg {
  height: 9vh;
}
