.TaskCard {
  height: 40px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: var(--user-blue);
}

.TaskCardInner {
  display: flex;
  align-items: center;
  height: 100%;
  width: 95%;
  margin-left: 3vw;
  margin-right: 3vw;
  justify-content: space-between;
}

.accept-task-btn {
  outline: 0;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid #40934f;
  color: white;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 6px;

  background: #40934f;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
  margin-right: 1vw;
  font-weight: bolder;
}

.accept-task-btn:hover {
  background-color: white;
  color: black;
}

.delete-btn-task {
  outline: 0;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid #ff4742;
  color: white;
  background: #ff4742;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
  margin-right: 1vw;
  font-weight: bolder;
  padding: 6px;
}

.delete-btn-task:hover {
  background-color: white;
  color: black;
}

.edit-btn-task {
  outline: 0;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid rgb(255, 208, 66);
  color: white;
  background: rgb(255, 208, 66);
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
  margin-right: 1vw;
  font-weight: bolder;
  padding: 6px;
}

.edit-btn-task:hover {
  background-color: white;
  color: black;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.save-edit-btn-task {
  outline: 0;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid rgb(255, 208, 66);
  color: white;
  background: rgb(255, 208, 66);
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
  height: 100%;
  width: 100%;
  margin-right: 1vw;
  font-weight: bolder;
}

.save-edit-btn-task:hover {
  background-color: white;
  color: black;
  scale: 1.1;
}
